import React from "react"
import { Link, graphql } from "gatsby"
import Icon from "@mdi/react"
import { mdiChevronLeft } from "@mdi/js"
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { grey, red } from "@material-ui/core/colors"
import "../components/hero.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero2 from "../components/hero2"
import ReleaseCards from "../components/releaseCards"

const useStyles = makeStyles(theme => ({
  paragraph: {
    ...theme.typography.body1,
  },
  title: {
    color: red[600],
    fontWeight: 100,
  },
  link: {
    textDecoration: "none",
  },
  backgroundReleases: {
    padding: theme.spacing(4, 0),
    borderTop: `10px solid ${grey[700]}`,
    backgroundColor: grey[100],
  },
  main: {
    minHeight: "65vh",
  },
}))

const Releases = ({ data }) => {
  const classes = useStyles()
  const releases = data.releases.edges[0].node.releases

  return (
    <>
      <Hero2 fluid={data.placeholderImage.childImageSharp.fluid} />
      <div className={classes.backgroundReleases}>
        <Layout>
          <Container component="main" className={classes.main} maxWidth="md">
            <SEO title="Releases" />
            <Typography component="div">
              <Box
                fontSize="h4.fontSize"
                textAlign="center"
                m={1}
                className={[classes.title, "arvo"].join(" ")}
              >
                Releases
              </Box>
            </Typography>

            <Box textAlign="center" m={1} className={classes.list}>
              <Grid container justify="center">
                <Grid item xs={12} sm={8}>
                  <Grid container spacing={2}>
                    <Link to="/" className={classes.link}>
                      <Button className={classes.moreReleases}>
                        <Icon path={mdiChevronLeft} size={1} color="grey" />
                        Back
                      </Button>
                    </Link>
                    <ReleaseCards releases={releases} limit={100} />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Layout>
      </div>
    </>
  )
}

export default Releases

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "CorComLogo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    releases: allDataJson(sort: { fields: releases___date, order: ASC }) {
      edges {
        node {
          releases {
            date
            link
            title
            headline
          }
        }
      }
    }
  }
`
