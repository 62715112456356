import React from "react"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import { grey, red } from "@material-ui/core/colors"

const useStyles = makeStyles(theme => ({
  title: {
    color: red[600],
    textTransform: "uppercase",
  },
  list: {
    fontSize: "0.85rem",
  },
  cardTitle: {
    fontSize: 14,
    color: red[600],
  },
  cardDiv: {
    backgroundColor: grey[300],
    cursor: "pointer",
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: grey[400],
    },
  },
  link: {
    textDecoration: "none",
  },
  releaseTitle: {
    color: grey[600],
    fontSize: 12,
  },
}))

const ReleaseCards = props => {
  const classes = useStyles()
  const { releases, limit } = props

  return releases.map((release, index) => {
    if (index < limit) {
      return (
        <Grid item xs={12} key={index}>
          <a
            href={release.link}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            <div className={classes.cardDiv}>
              <Typography
                variant="body2"
                component="p"
                gutterBottom
                className={classes.releaseTitle}
              >
                {release.title}
              </Typography>
              <Typography
                className={classes.cardTitle}
                color="textSecondary"
                gutterBottom
              >
                {release.headline}
              </Typography>
            </div>
          </a>
        </Grid>
      )
    }
  })
}

export default ReleaseCards
